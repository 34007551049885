import { Typography } from '@mui/material';
import React from 'react';

export default function Maintenance() {
	return (
		<div>
			<Typography variant="h3" gutterBottom>
				Health Hub Maintenance in Progress
			</Typography>
			<Typography variant="body1" paragraph gutterBottom>
				Dear Health Hub Users,
			</Typography>
			<Typography variant="body1" paragraph gutterBottom>
				Our servers are currently undergoing scheduled maintenance. The app will be temporarily inaccessible from 12:30 PM to 1:30 PM on
				Wednesday, 19th March.
			</Typography>
			<Typography variant="body1" paragraph gutterBottom>
				We apologize for any inconvenience this may cause and appreciate your patience.
			</Typography>
			<Typography variant="body1" gutterBottom>
				Thank you for your understanding.
			</Typography>
			<Typography variant="body1" gutterBottom>
				/The Health Hub team.
			</Typography>
		</div>
	);
}
