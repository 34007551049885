/**Participant roles */
const LEADER = 'leader';
const ORGANIZER = 'organizer';
const MODERATOR = 'moderator';
const ATTENDEE = 'attendee';

/**Activity status */
const ACTIVE = 1;
const CLOSE = 2;
const ARCHIVED = 3;

/**Data access */
const PUBLIC = 'public';
const PRIVATE = 'private';

export default {
	ROLE: {
		LEADER,
		ORGANIZER,
		MODERATOR,
		ATTENDEE,
	},
	STATUS: {
		ACTIVE,
		CLOSE,
		ARCHIVED,
	},
	DATA_ACCESS: {
		PUBLIC,
		PRIVATE,
	},
	SCOPES: {
		MANAGE: {
			ACCESS: 'activities.manage.access',
      VIEW: 'activities.manage.view',
			EDIT: 'activities.manage.edit',
			EDIT_EVENTS: 'activities.manage.edit_events',
			ADD_INVITEES: 'activities.manage.add_invitees',
			REMOVE_INVITEES: 'activities.manage.remove_invitees',
			EDIT_INVITEES: 'activities.manage.edit_invitees',
			DELETE: 'activities.manage.delete',
      DEACTIVATE: 'activities.manage.deactivate',
      REACTIVATE: 'activities.manage.reactivate',
			START_SESSION: 'activities.manage.start_session',
			CLOSE_SESSION: 'activities.manage.close_session',
      TAGS: 'activities.manage.tags',
			TAGS_EDIT: 'activities.manage.tags_edit',
		},
		CHAT: {
			CHAT: 'activities.chat',
			UPLOAD_FILE: 'activities.chat.upload_file',
			MESSAGE: 'activities.chat.message',
			MESSAGE_DELETE: 'activities.chat.message_delete',
		},
	},
};
