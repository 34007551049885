export const FETCH_ACTIVITIES   = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITY   = 'FETCH_ACTIVITY';
export const POST_ACTIVITY   = 'POST_ACTIVITY';
export const DELETE_ACTIVITY   = 'DELETE_ACTIVITY';
export const FETCH_ACTIVITY_EVENTS   = 'FETCH_ACTIVITY_EVENTS';
export const FETCH_ACTIVITY_ATTENDEES   = 'FETCH_ACTIVITY_ATTENDEES';
export const REMOVE_ACTIVITY_ATTENDEE   = 'REMOVE_ACTIVITY_ATTENDEE';
export const REMOVE_ACTIVITY_EVENT   = 'REMOVE_ACTIVITY_EVENT';
export const CLEAR_ACTIVITY   = 'CLEAR_ACTIVITY';
export const UPDATE_ACTIVITY_EVENTS   = 'UPDATE_ACTIVITY_EVENTS';
export const INVITATION_ACTIVITY   = 'INVITATION_ACTIVITY';
export const FETCH_ACTIVITY_FILES   = 'FETCH_ACTIVITY_FILES';
export const SET_ACTIVITY_FILE   = 'SET_ACTIVITY_FILE';
export const REMOVE_ACTIVITY_FILE   = 'REMOVE_ACTIVITY_FILE';