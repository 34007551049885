import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import { createRoot } from 'react-dom/client';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Maintenance from './components/Generic/Maintenance';
// ReactDOM.render(<App />, document.getElementById('root'));

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (process.env.REACT_APP_MAINTENANCE && process.env.REACT_APP_MAINTENANCE == 'true') {
  root.render(<Maintenance />);
} else {
  root.render(<App />);
}