import axios from 'axios';
import { store } from '../../../store.js';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import {
	FETCH_ACTIVITIES,
	FETCH_ACTIVITY,
	POST_ACTIVITY,
	DELETE_ACTIVITY,
	FETCH_ACTIVITY_EVENTS,
	FETCH_ACTIVITY_ATTENDEES,
	UPDATE_ACTIVITY_EVENTS,
	REMOVE_ACTIVITY_ATTENDEE,
	REMOVE_ACTIVITY_EVENT,
	INVITATION_ACTIVITY,
	CLEAR_ACTIVITY,
	FETCH_ACTIVITY_FILES,
	SET_ACTIVITY_FILE,
	REMOVE_ACTIVITY_FILE,
} from './types';
import { FETCH_TAGGED_FILES, HANDLE_TABLE_LOADING } from '../../Generic/actions/types';
import { convertToFormData, addDelayAction, handleLoadingAction, handleSplashScreenAction } from '../../Generic/actions/genericActions';
import eventsService from '../../../helpers/Events';
import { parseDateOffset } from '../../../helpers/functions/functions';
import { setTableCountAction } from '../../Generic/actions/tableActions';
import { apiRequest } from '../../../helpers/functions/request';
import { reset } from 'redux-form';

export const fetchTableActivitiesAction = (table_id, config, pagination, filters) => async (dispatch) => {
	const { localize } = store.getState();
	const translate = getTranslate(localize);

	var fields = [];

	if (config) {
		config.map((field) => {
			if (field.options.renderOnExpand) {
				field.options.display = 'excluded';
			}
			fields.push({
				name: field.label,
				label: translate('table_column_' + field.label),
				options: {
					...field.options,
				},
			});
		});
	}

	await dispatch({
		type: HANDLE_TABLE_LOADING,
		payload: { table_id, loading: true },
	});

	await dispatch({
		type: FETCH_ACTIVITIES,
		payload: {
			fields,
			data: [],
			total_count: 0,
		},
	});

	let response = await dispatch(fetchActivitiesAction(pagination, filters, fields)).catch((error) =>
		dispatch({
			type: HANDLE_TABLE_LOADING,
			payload: { table_id, loading: false },
		})
	);

	await dispatch({
		type: HANDLE_TABLE_LOADING,
		payload: { table_id, loading: false },
	});
	if (response) {
		return dispatch(setTableCountAction(table_id, response.total_items));
	}
};

export const fetchActivitiesAction =
	(pagination = {}, filters = {}, fields = []) =>
	async (dispatch) => {
		const state = store.getState();

		if (filters['free_spots'] == 'true') {
			filters['free_spots'] = true;
		} else {
			delete filters['free_spots'];
		}

		var params = { ...pagination, ...filters };

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/activities`,
			params,
		});

		await dispatch({
			type: FETCH_ACTIVITIES,
			payload: {
				fields,
				data: response.status == 204 ? [] : response.data.items,
				total_count: response.status == 204 ? 0 : response.data.total_items,
			},
		});

		return response.data;
	};

export const fetchActivityAction =
	(id, loading = true, callback = false, callback_error = false) =>
	async (dispatch) => {
		if (loading) {
			await dispatch(handleSplashScreenAction(true));
		}

		const state = store.getState();

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/activities/` + id,
			handle_error: (error, trigger_event) => {
				if (loading) {
					dispatch(handleSplashScreenAction(false));
				}
				if (callback_error) {
					callback_error();
				} else {
					trigger_event();
				}
			},
		});

		if (response) {
			await dispatch({
				type: FETCH_ACTIVITY,
				payload: response.data,
			});

			if (callback) {
				await callback(response.data);
			}
		}

		if (loading) {
			console.log('loading off');
			await dispatch(handleSplashScreenAction(false));
		}

		return;
	};

export const fetchActivitybyTokenAction =
	(token, loading = true) =>
	(dispatch) => {
		if (loading) {
			dispatch(handleLoadingAction(true));
		}

		const state = store.getState();

		axios
			.get(state.config.apihost + `/activities/token/` + token)
			.then((res) => {
				dispatch({
					type: FETCH_ACTIVITY,
					payload: res.data,
				});

				if (loading) {
					dispatch(handleLoadingAction(false));
				}

				dispatch(push('/activity/' + res.data.id));
			})
			.catch((error) => {
				if (loading) {
					dispatch(handleLoadingAction(false));
				}
				console.log(error);
				dispatch(push(state.users.whoami.redirect_url ? state.users.whoami.redirect_url : '/dashboard'));
			});
	};

export const postActivityAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.apihost + `/activities`, data)
		.then((res) => {
			dispatch({
				type: POST_ACTIVITY,
				payload: res.data,
			});
			// console.log(events);
			// events.forEach(event => {
			// 	dispatch(postActivityEvent(event, res.data.id));
			// });

			dispatch(push('/activity/' + res.data.id + '/edit'));

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchActivityAction = (form, status) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);
	let data = null;
	if (form) {
		data = convertToFormData(state.form[form].values);
	} else {
		data = new FormData();
		data.append('status', status);
	}

	axios
		.patch(state.config.apihost + `/activities/` + state.activities.activity.id, data)
		.then((res) => {
			dispatch({
				type: FETCH_ACTIVITY,
				payload: res.data,
			});
			// console.log(events);
			// events.forEach(event => {
			// 	dispatch(postActivityEvent(event, res.data.id));
			// });

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_activity') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const deleteActivityAction = (activity_id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/activities/` + activity_id)
		.then((res) => {
			dispatch({
				type: DELETE_ACTIVITY,
				payload: res.data,
			});

			dispatch(
				addDelayAction(push('/activities'), () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_activity') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postActivityEventAction = (event, updateEventsStateId) => (dispatch) => {
	const state = store.getState();

	event.start = new Date(event.start_date.setTime(event.start_time.getTime()));
	event.end = new Date(event.start.getTime() + Number(event.duration) * 60000);

	var start = parseDateOffset(event.start).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = parseDateOffset(event.end).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var body = new FormData();

	body.append('start', start);
	body.append('end', end);
	body.append('location_type', event.location_type);
	body.append('location', event.location);

	axios
		.post(state.config.apihost + `/activities/` + state.activities.activity.id + `/events`, body)
		.then((res) => {
			dispatch({
				type: FETCH_ACTIVITY_EVENTS,
				payload: res.data[0],
			});

			updateEventsStateId(res.data[0].id);

			// eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity_event') })
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchActivityEventAction = (event, updateEventsToUpdateState) => (dispatch) => {
	const state = store.getState();

	event.start_time.setDate(event.start_date.getDate());

	event.start = new Date(event.start_date);
	event.start.setTime(event.start_time.getTime());
	event.end = new Date(event.start.getTime() + Number(event.duration) * 60000);

	var start = parseDateOffset(event.start).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = parseDateOffset(event.end).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var body = new FormData();

	body.append('start', start);
	body.append('end', end);
	body.append('location_type', event.location_type);
	body.append('location', event.location);

	axios
		.patch(state.config.apihost + `/activities/` + state.activities.activity.id + `/events/` + event.id, body)
		.then((res) => {
			dispatch({
				type: UPDATE_ACTIVITY_EVENTS,
				payload: res.data,
			});

			updateEventsToUpdateState(res.data.id);

			// eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity_event') })
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const removeActivityEventAction = (event_id, removeActivityEventFromState) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/activities/` + state.activities.activity.id + `/events/` + event_id)
		.then((res) => {
			dispatch({
				type: REMOVE_ACTIVITY_EVENT,
				payload: res.data,
			});

			removeActivityEventFromState(res.data.id);

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_activity_event') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const inviteActivityAttendeeAction = (attendee_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var data = new FormData();
	data.append('users_id', attendee_id);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/activities/` + state.activities.activity.id + `/attendees`,
		data,
	});

	await dispatch({
		type: FETCH_ACTIVITY_ATTENDEES,
		payload: response.data,
	});

	return eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity_invite_attendees') });
};

export const removeActivityAttendeeAction = (attendee_id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/activities/` + state.activities.activity.id + `/attendees/` + attendee_id)
		.then((res) => {
			dispatch({
				type: REMOVE_ACTIVITY_ATTENDEE,
				payload: res.data,
			});

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_activity_invite_attendees') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const notifyActivityAttendeeAction = (id, notify) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.post(state.config.apihost + `/activities/` + id + `/notify/`, {
			notify: notify,
		})
		.then((res) => {
			dispatch({
				type: INVITATION_ACTIVITY,
				payload: {
					attendees: res.data.attendees,
				},
			});
			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_activity_invition') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const clearActivityAction = () => async (dispatch) => {
	await dispatch(handleSplashScreenAction(true));
	await dispatch({
		type: CLEAR_ACTIVITY,
	});

	return dispatch(handleSplashScreenAction(false));
};
export const patchActivityAttendeeAction =
	(activity_id, attendee_id, params, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		const response = await apiRequest({
			method: 'patch',
			url: state.config.apihost + '/activities/' + activity_id + '/attendees/' + attendee_id,
			data: params,
			success_message: translate('activity_meeting_attendee_updated'),
			// submit_form: 'event_invitees_edit',
		});

		dispatch(fetchActivityAction(activity_id));

		// 		if (callback) {
		// 			callback(event.invitees);
		// 		}
		// 	}
		// }

		return;
	};

/**
 * Calls api endpoint to upload a file to an Activity
 * @param {formData} data form data to use as body params for the request, contains the file
 * @param {int} activity_id
 * @returns
 */
export const postFileChatAction = (data, activity_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + '/storage/activities/' + activity_id,
		data,
		submit_form: 'activity_upload_file_chat',
		success_message: translate('alert_post_uploadfile'),
	});

	const path = state.router.location.pathname.split('/');

	if (path.length == 3 && path[1] === 'activity' && path[2] == activity_id) {
		return dispatch({
			type: SET_ACTIVITY_FILE,
			payload: response.data,
		});
	} else {
		return;
	}
};

/**
 * Calls api endpoint to get activity files
 * @param {int} activity_id
 * @returns
 */
export const getActivityFilesAction = (activity_id) => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + '/storage/activities/' + activity_id,
	});

	return dispatch({
		type: FETCH_ACTIVITY_FILES,
		payload: response.data,
	});
};

/**
 * Calls api endpoint to get activity files
 * @param {int} activity_id
 * @param {string} uuid
 * @returns
 */
export const removeActivityFileAction = (activity_id, uuid) => async (dispatch) => {
	return dispatch({
		type: REMOVE_ACTIVITY_FILE,
		payload: { activity_id, uuid },
	});
};

/**
 * Calls api endpoint to upload a file to an Activity
 * @param {formData} data form data to use as body params for the request, contains the file
 * @param {int} activity_id
 * @returns
 */
export const postTagAction = (tag_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	return apiRequest({
		method: 'post',
		url: state.config.apihost + '/activities/' + state.activities.activity.id + '/tags/' + tag_id,
		success_message: translate('alert_post_activity_tag'),
	});
};

/**
 * Calls api endpoint to upload a file to an Activity
 * @param {formData} data form data to use as body params for the request, contains the file
 * @param {int} activity_id
 * @returns
 */
export const deleteTagAction = (tag_id) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	return apiRequest({
		method: 'delete',
		url: state.config.apihost + `/activities/` + state.activities.activity.id + `/tags/` + tag_id,
		submit_form: 'ticket_patient_delete',
		success_message: translate('alert_delete_activity_tag'),
	});
};

/**
 * Calls api endpoint to upload tag files to a Activity
 * @param {int} entity_id
 * @returns
 */
export const getActivityTagFileAction = (entity_id) => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/activities/` + entity_id + `/taggedfiles`,
	});
	return dispatch({
		type: FETCH_TAGGED_FILES,
		payload: response.data,
	});
};
